<template>
  <v-card-text>
    <v-card>
      <v-card-text>
        <site-locations-table
          :siteLocations="siteLocations"
          :siteLocationsCount="siteLocationsCount"
          :isLoading="isLoading"
          :singleLineAddress="true"
          @updateSiteLocations="handleUpdateSiteLocations($event)"
        >
        </site-locations-table>
      </v-card-text>
    </v-card>
  </v-card-text>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import SiteLocationsTable from "@admin/components/SiteLocationsTable";

export default {
  name: "SiteLocationsIndex",
  components: {
    SiteLocationsTable,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState("siteLocations", ["siteLocations", "siteLocationsCount"]),
    ...mapGetters(["acceptRoles"]),
    showAddSiteLocationButton() {
      return this.acceptRoles(["Manager", "Admin", "Editor", "Super User"]);
    },
  },
  methods: {
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setTitleObject",
    ]),
    ...mapActions("siteLocations", [
      "clearSiteLocations",
      "getSiteLocations",
      "searchSiteLocations",
    ]),
    async handleUpdateSiteLocations(options) {
      this.isLoading = true;
      await this.getSiteLocations(options);
      this.isLoading = false;
    },
  },
  async mounted() {
    if (!this.siteLocations.length) {
      this.isLoading = true;
      await this.getSiteLocations();
      this.isLoading = false;
    }
    this.setBreadcrumbs();
    this.setTitleObject("Clinical Trial Sites");

    const actionButtons = [];

    actionButtons.push({
      attributes: {
        searchCallback: (searchQuery) => this.searchSiteLocations(searchQuery),
      },
      component: "SearchBar",
      index: 0,
    });

    if (this.showAddSiteLocationButton) {
      actionButtons.push({
        attributes: {
          to: "/site-locations/add",
        },
        component: "AddButton",
        text: "Add Site",
        index: 1,
      });
    }
    this.setActionButtons(actionButtons);
  },
  destroyed() {
    this.clearActionButtons();
    this.clearSiteLocations();
    this.clearTitleObject();
  },
};
</script>

<style scoped></style>
